import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Flex, Heading, Link,
} from '@chakra-ui/react';
import Layout from '../components/layout';
import data from '../data.json';

function Historico({ location }) {
  return (
    <Layout location={location.pathname}>
      <Container mt={5} padding={4}>
        <Heading as="h1" textAlign="center" padding={4}>Edições Anteriores</Heading>
        <Heading as="h2" mb={5} size="md" textAlign="center">Baixe as provas das edições anteriores:</Heading>
        <Flex
          justifyContent="space-evenly"
          wrap="wrap"
        >
          {data.historico.edicoes.map(
            (edicao) => (
              <Link
                borderColor="primary"
                borderWidth={3}
                color="primary"
                href={`/arquivos/edicao${edicao}.zip`}
                key={edicao}
                mx={5}
                my={3}
                px={5}
                py={3}
                _hover={{
                  background: 'primary',
                  color: 'white',
                }}
              >
                Edição
                {' '}
                {edicao}
              </Link>
            ),
          )}
        </Flex>
      </Container>
    </Layout>
  );
}

Historico.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Historico;
